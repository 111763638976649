import TypeText from '../common/TypeText';
import projectsJpg from '../../img/projects.jpg';
import GetData  from './GetData';
import Footer from '../common/Footer'


const learningStyle = {
    backgroundImage : `url(${projectsJpg})`,
}

function Projects(){
    return(
        <>
            <section className="midea-container"> 
                <section className="midea-banner midea-projects" style={learningStyle}> 
                    <section className="midea-title">
                        <TypeText dynamicText="console.log('Projects')"/>
                    </section>
                </section> 
                <GetData />           
            </section>
            <Footer />
        </>
    )

}

export default Projects;