import React,{useRef,useEffect} from 'react';


function TypeText({dynamicText}){
    const renderDiv = useRef();

    useEffect(() =>{     
        
        const codeString = dynamicText || `Enter text Here` ;
        const splitString = codeString.split("");
        const h1Tag = renderDiv.current ; 
        const displayText = element =>  h1Tag.insertAdjacentHTML('beforeend',`<span>${element} </span>`);

        const delayLoop = () => {
            return (name, i) => {
              setTimeout(() => {
                displayText(name);
              }, i * 100);
            }
          };
        
          splitString.forEach(delayLoop(displayText, 1));

    },[dynamicText]);

    return(
        <h1 ref={renderDiv} className="midea-type-text"> </h1>
    )

}



export default TypeText;