import axios from 'axios';
import {useState,useEffect,} from 'react';


const GetData = () => {

    const [ projectData,setProjectData] = useState(null);

    useEffect(()=>{
        (async () =>{
            const response = await axios.get('https://api.github.com/users/manny516/repos');
            setProjectData(response.data);
        })()
    },[]);

    return (
        <>
            <section className="midea-repo-count">      
                <h1>Current Repo Projects :{projectData !== null ? projectData.length : ' ' } </h1> 

            </section>
            <section className="midea-git-container">

                { projectData && projectData.map((items,index) =>{


                    return(
                        <section className="midea-git-projects" key={index}>
                            <h2 className="midea-title">{items.name.includes("_") ? items.name.replace(/_/g,' ') : items.name.replace(/-/g,' ')}</h2>
                            <p className="midea-desc">{items.description}  </p>
                            Language : <span className="midea-lang">  {items.language} </span> 
                            <button><a href={items.html_url} target="_blank" rel="noreferrer"> Git Link </a></button>

                        </section>

                    )

                })}

            </section>
        </>
    )
}
export default GetData
