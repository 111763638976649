import React from 'react';
import ReactDOM from 'react-dom';
import './css/main.scss';
import 'normalize.css';
import Root from './comp/common/Root';
import {BrowserRouter as Router} from 'react-router-dom'


const styleMode = () => {
  const urlPath = window.location.href.split('/');
  if(urlPath[3] === ""){
    return "dark-mode";
  }else{
    return "light-mode";
  }
}

ReactDOM.render(
 <>
    <Router> 
      <Root cssResult={styleMode} /> 
    </Router>
    
 </>
 , document.getElementById('root')
);

