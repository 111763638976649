import VideoBg from '../common/VideoBG';
import mp4 from '../../video/video.mp4';
import mobileImg from '../../img/projectsbg.jpg';
import TypeText from '../common/TypeText';
import {isMobile,BrowserView} from 'react-device-detect';
import Footer from '../common/Footer'


function About(){

    if(isMobile){
        var bannerBg = {
            backgroundImage : `url(${mobileImg})`
        }
    }
    return(

        <>
            <section className="midea-container"> 
                <section  className="midea-banner" style={bannerBg}>
                    <section className="midea-title">
                        <TypeText dynamicText="console.log('About')" />
                    </section>
                    <BrowserView>
                        <VideoBg videoUrl={mp4} />
                    </BrowserView>
                </section>
                <article className="midea-bio-text">

                <p> <span className="special-letter">W</span>ith a passion for programming and design, I can honestly say that 
                it all began as a teen. With a huge love for video games at that time, my passion began with development. I had a 
                deep interest in code whether it was using the command line to hack portable game consoles: such as the (Play Station Portable), 
                or currently using code to enhance features on VW/Audi modules. During this time I was very fascinated with code. I would spend hours 
                trying out homebrews and playing around with the command line. 
                </p>
                <p> 
                I'm always excited to network and meet new people. I believe that no matter whom you meet it can always be a learning experience. As a firm 
                believer in teamwork, I love to work in groups whether big or small, working on new projects, and taking on new challenges is a huge part of 
                what I enjoying doing. Because I love what I do I always stay up-to-date on new technologies whether it's programming, code scripts, design trends, 
                or even cool tech gadgets. Keeping up with technology is what inspires me to stay on the cutting edge.
                </p>

                <p>
                So let us build something great with our skills and talents; who knows what inspiration we can create. 
                </p>


                </article>
            </section>

            <Footer />
        </>
    )

}

export default About;