import React from 'react';
import TypeText from '../common/TypeText';
import learningJpg from '../../img/learning.jpg';
import DataGrab from './DataGrab';
import Footer from '../common/Footer'




function Learning(){

    
    let learningStyle = {
        backgroundImage : `url(${learningJpg})`,
    }
   
    return(

        <>
            <section className="midea-container"> 
                <section className="midea-banner" style={learningStyle}> 
                    <section className="midea-title">
                        <TypeText dynamicText="console.log('Learning')"/>
                    </section>
                </section>    
                <section className="midea-learning-summary">
                    <article className="midea-learning-intro">
                        <p> 
                        Learning is a huge part of being a developer, as technologies change the tools 
                        we use to improve. Here is a list of resources that I’ve used and completed to 
                        expand my knowledge and skills as a developer. Whether it be Vanilla Javascript 
                        or React JS, I always enjoy learning and improving my skills.
                        </p>
                    </article>

                </section>
                <DataGrab/>
                
            </section>
            <Footer />
        </>
    )

}


export default Learning;