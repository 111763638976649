
function VideoBg({videoUrl}){
    return(
        <>
            <section className="midea-bg-cover"></section>
            <video autoPlay muted loop className="midea-bg-video" >
                <source src={videoUrl} type="video/mp4" />
            </video>
        </>
    );

}



export default VideoBg;