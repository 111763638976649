import React,{useEffect,useState,useRef} from 'react';
import {Routes,Route,useLocation,Link} from 'react-router-dom';
import logo from '../../img/logo.png';
import darkLogo from '../../img/black-logo.png';
import mobilebg from '../../img/homebg.jpg';
import NavLinks from '../common/NavLinks';
import Home from '../home/Home';
import About from '../about/About';
import Projects from '../projects/Projects';
import Learning from  '../learning/Learning';
import {FaHamburger,FaTimesCircle} from 'react-icons/fa';
import {isMobile} from 'react-device-detect';



function Root({cssResult}){

    const [cssMode,setCssMode] = useState(cssResult());
    const [modalToggle,setModalToggle] = useState(false);
    const componentElement = useRef();
    const location = useLocation();

    useEffect(() =>{
        
        console.log(`Use EFfect : ${cssMode}`);
        if(isMobile && location.pathname === '/'){
            let rootDiv = componentElement.current.parentElement
            rootDiv.setAttribute("style",`background: url(${mobilebg})`);
        }else{
            componentElement.current.parentElement.removeAttribute("style");
        }
        console.log(location.pathname);
        
    },[cssMode,location]);

    
    /* Created modal window Container*/
    function modalWindow(){
        const modalHTML = {
            modal : `<section class="midea-modal-tint">     
            
            </section>`
        }
        setModalToggle(true);
        componentElement.current.insertAdjacentHTML('afterbegin',modalHTML.modal);
    }

    /* Change Cssmode state and remove model window element*/
    function cssStateBtn(e){
        setTimeout(() =>{
            setCssMode(cssResult())
        },20); 
        if(modalToggle){
            setModalToggle(false);
            componentElement.current.firstChild.remove();
        }
    }

    /*  Grab Parent element and remove the modal window change modal state to False*/
    function grabParent(e){
        componentElement.current.firstChild.remove();
        setModalToggle(false);
    }

    return(
        
        <>
            { modalToggle ? <> <FaTimesCircle className="exitBtn" onClick={grabParent} />  <NavLinks customClass="midea-mobile-nav" linkEvent={cssStateBtn} /> </>: "" } 
                  
            <header ref={componentElement} className="midea-header">

                <section className="midea-logo midea-left-column"> 
                    <Link to="/" onClick={cssStateBtn}><img src={(cssMode === "dark-mode") ? logo : darkLogo} alt="Manny Idea" /> </Link>
                </section>    
                
                <nav className={`midea-nav midea-right-column ${cssMode}`}>
                    <section className="midea-mobile-menu"> 
                        <FaHamburger onClick={modalWindow} fontSize={40} color={(cssMode === "dark-mode") ? "#FFF" : "#000"} cursor="pointer"/>
                    </section>

                    <section className="midea-desktop-menu"> 
                        <NavLinks linkEvent={cssStateBtn} />
                    </section>
                </nav>

            </header>

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/learning" element={<Learning />} />
            </Routes>

        </>

    )
    

}


export default Root;