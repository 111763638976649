import {useState,useEffect} from 'react';
import axios from 'axios';


const Youtube = () => {

    const [youtubeData,setYoutubeData] = useState(null);

    useEffect(() =>{
        ( async () =>{
            const getYoutubeData =  await axios.get('./api/onlinelearning.json');
            setYoutubeData(getYoutubeData.data);
        })()
    },[])
   
    console.log(youtubeData);

    return (
        <>
            {
                youtubeData && youtubeData.map((items,index) =>{

                    return(

                        <article className="midea-youtube-items" key={index}>

                            <figure className="midea-youtube-thumb"> <img src={items.thumb} alt={items.name} /></figure>
                            
                            <figure className="midea-youtube-info">
                                <h2 className="midea-youtube-name">{items.name} </h2>
                                <button> <a href={items.url} target="_blank" rel="noreferrer">View</a></button>
                            </figure>
    
                        </article>
                    )
                
                })

            }


        </>
    )
}

export default Youtube
