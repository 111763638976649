const Footer = () => {
    const date = new Date();
    return (
        <footer>
            <p> Copyright © {date.getFullYear()}  Mannyidea </p> 
        </footer>
    )
}

export default Footer
