import VideoBg from '../common/VideoBG';
import TypeText from '../common/TypeText';
import mp4 from '../../video/Source.mp4';
import {BrowserView} from 'react-device-detect';
import Youtube from './Youtube';
import Footer from '../common/Footer'


function Home(props){
    

    return(
        <>
        <section ref={props.homeRef} className="midea-container"> 
            <BrowserView>
                <VideoBg videoUrl={mp4} />
            </BrowserView>
            <section className="midea-intro" >
                {/* <h1 ref={renderDiv} className="midea-type-text"></h1> */}
                <TypeText dynamicText="console.log('Hello World')" />
            </section>

            <section className="midea-youtube"> 

                <article className="midea-youtube-intro">
                    <p> When I'm not working I of course like to browse on Youtube: Don't we All? 
                        But I have a few of my go-to Youtube channels I like to visit, especially 
                        if I'm learning new technologies or just need extra help besides Stack Overflow
                    </p> 
                </article>
                <article className="midea-youtube-data">
                    <Youtube />
                </article>
            </section>
        </section>
        <Footer />
    </>
    )

}


export default Home;