import React,{useState,useEffect,useRef} from 'react';
import {FaTimesCircle} from 'react-icons/fa';
import Axios from 'axios';


function DataGrab() {
    const [grabData,setData] = useState(null);
    const [filterNav,setFilterNav] = useState(['PHP','Javascript','Tooling','Marketing','Computer Science','Css']);
    const trackFilter = useRef();
    
    const orginalRef = useRef({
        orginalVal : null
    });
    useEffect(() =>{
     (async () =>{
            const response = await Axios.get('./api/learning.json')
            setData(response.data);
            orginalRef.current.orginalVal = response.data;
        })()
        
    },[]);
    
    function currentClick(e){
       
        const currentTitle = e.currentTarget.innerHTML;
        const filterItems = trackFilter.current.lastElementChild;
        const newArray = grabData.filter((study) =>study.category !== currentTitle);
        setData(newArray);
        
        
        filterNav.splice(filterNav.indexOf(currentTitle),1);
        setFilterNav(filterNav);
        
        console.log(filterNav.indexOf('Javascript'));
        if(filterItems.innerText !== 'Clear All'){
            setFilterNav([...filterNav,'Clear All']);
            
        }

        console.log(currentTitle);

        

        if(currentTitle === "Clear All" ){
            setFilterNav(['PHP','Javascript','Tooling','Marketing','Computer Science','Css']);
           setData(orginalRef.current.orginalVal);
         }
    }

    
    return (
        <>
            <section ref={trackFilter} className="midea-filter"> 
             
                {
                    filterNav.map((filterItem,index)=>{
                        return (
                        <span className="midea-filter-btn" key={index}> 
                            <button onClick={currentClick}>{filterItem}</button>
                            <figure className="midea-delete-filter"> <FaTimesCircle /> </figure>
                        </span>
                            )
                    })
                }
            </section>

            <section className="midea-studies-container"> 
            {
                
                grabData &&
                grabData.map((study,index) =>{
                    return(
                        <section className="midea-studies" key={index}>
                            <h2>{study.name}</h2>
                            <figure> 
                                <img src={study.thumb} alt={study.name}/>
                            </figure>
                            <span> Category : {study.category}</span>
                            <button className="midea-link-btn"> <a href={study.url} target="_blank" rel="noreferrer"> View More </a> </button>
                        </section>
                    )
                })

            }
            </section>
            </>
     )
}

export default DataGrab
