import {Link} from 'react-router-dom';

function NavLinks({customClass,linkEvent}){

    return(
        <nav className={customClass || ''}>
            <Link to="/" onClick={linkEvent}>Home</Link>
            <Link to="about" onClick={linkEvent}>About</Link>
            <Link to="projects" onClick={linkEvent}> Projects</Link>
            <Link to="learning" onClick={linkEvent} > Learning</Link>
        </nav>
    );
}

export default NavLinks;